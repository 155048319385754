import { apolloClient } from "../../../apollo/apolloVueClient";
import {
	CREATE_BONUSES,
	DELETE_BONUS,
	UPDATE_BONUSES,
} from "../../../graphql/mutations/bonuses_mutations";
import {
	CREATE_ALLOWANCE,
	DELETE_ALLOWANCE,
	UPDATE_ALLOWANCE,
} from "../../../graphql/mutations/allowance_mutations";
import {
	LIST_TAGS,
	SEARCH_FOR_ALLOWANCE,
	LIST_BONUSES,
	LIST_ALLOWANCES,
	SEARCH_BONUSES,
} from "../../../graphql/queries/allQueries";

export default {
	namespaced: true,
	// initial state
	state: {
		bonuses: [],
		allowance: {},
		currentPage: null,
		allowanceCurrentPage: null,
		totalPages: null,
		displayName: null,
		currentTab: null,
		tags: [],
		bonusCurrentPage: null,
	},

	//getters
	getters: {
		allBonuses: (state) => {
			return state.bonuses;
		},
		bonusCurrentPage: (state) => {
			return state.bonusCurrentPage || 1;
		},
		allAllowance: (state) => {
			return state.allowance.data;
		},
		hasBonus: (state) => {
			return state.bonuses && state.bonuses.length > 0;
		},
		currentPage: (state) => {
			return state.currentPage || 1;
		},
		allowanceCurrentPage: (state) => {
			return state.allowanceCurrentPage;
		},
		totalPages: (state) => {
			return state.totalPages;
		},
		currentTab: (state) => {
			return state.currentTab || "bonus";
		},

		tags: (state) => {
			return state.tags;
		},
	},
	// mutations
	mutations: {
		SET_BONUS_CURRENT_PAGE(state, payload) {
			state.bonusCurrentPage = payload;
		},
		SET_CURRENT_TAB(state, payload) {
			state.currentTab = payload;
		},

		ADD_BONUS(state, payload) {
			let bonuses = state.bonuses.concat(payload);
			state.bonuses = bonuses;
		},
		ADD_ALLOWANCE(state, payload) {
			let allowances = state.allowance.concat(payload);
			state.allowance = allowances;
		},
		SET_BONUS(state, payload) {
			state.bonuses = payload;
		},
		SET_ALLOWANCE(state, payload) {
			state.allowance = payload;
		},
		SET_CURRENT_PAGE(state, payload) {
			state.currentPage = payload + 1;
		},
		SET_TOTAL_PAGE(state, payload) {
			state.totalPages = payload;
		},
		EDIT_BONUS(state, payload) {
			state.bonuses = state.bonuses.map((emp) => {
				if (emp.id == payload.bonusID) {
					return payload;
				}
				return emp;
			});
		},
		EDIT_ALLOWANCE(state, payload) {
			state.allowance = state.allowance.map((emp) => {
				if (emp.id == payload.allowanceID) {
					return payload;
				}
				return emp;
			});
		},
		SEARCH_BONUS(state, payload) {
			state.bonuses = payload;
		},
		SEARCH_ALLOWANCE(state, payload) {
			state.allowance = payload;
		},
		DELETE_BONUS(state, bonusID) {
			let bonuses = state.bonuses.filter((emp) => emp.id != bonusID);
			state.bonuses = bonuses;
		},
		DELETE_ALLOWANCE(state, allowanceID) {
			let allowance = state.allowance.filter((emp) => emp.id != allowanceID);
			state.allowance = allowance;
		},
		SET_TAGS(state, payload) {
			state.tags = payload;
		},
	},

	// actions
	actions: {
		async createBonus(context, payload) {
			const createBonusInput = payload.map((obj) => {
				return {
					startDate: obj.startDate,
					endDate: obj.endDate,
					monthlyBonus: obj.monthlyBonus,
					totalBonus: obj.totalBonus,
					description: obj.description,
					employeeId: obj.employeeId,
				};
			});
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: CREATE_BONUSES,
						variables: {
							createBonusInput: createBonusInput,
						},
					})
					.then(() => {
						context.dispatch("fetchBonuses", 1);
						resolve();
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},
		//CREATE ALLOWANCE ON SERVER
		async createAllowance(context, payload) {
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: CREATE_ALLOWANCE,
						variables: {
							allowanceInput: payload,
						},
					})
					.then(() => {
						context.dispatch("fetchAllowance", 1);
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		// GET ALL BONUSES FROM SERVER
		async fetchBonuses({ commit }, query) {
			try {
				const response = await apolloClient.query({
					query: LIST_BONUSES,
					fetchPolicy: "network-only",
					variables: {
						page: query - 1,
						size: 20,
					},
				});
				commit("SET_BONUS", response.data.listBonuses.data);
				commit(
					"SET_CURRENT_PAGE",
					response.data.listBonuses.pageable.number
				);
				commit(
					"SET_TOTAL_PAGE",
					response.data.listBonuses.pageable.totalPages
				);
			} catch (error) {
				console.error(error);
			}
		},

		async fetchAllowance({ commit }, query) {
			try {
				let { data } = await apolloClient.query({
					query: LIST_ALLOWANCES,
					fetchPolicy: "network-only",
					variables: {
						page: query - 1,
						size: 20,
					},
				});
				commit("SET_ALLOWANCE", data.listAllowances);
				commit("SET_CURRENT_PAGE", data.listAllowances.pageable.number);
				commit("SET_TOTAL_PAGE", data.listAllowances.pageable.totalPages);
			} catch (error) {
				console.log(error);
			}
		},

		//UPDATE BONUS ON SERVER
		async editBonus(context, payload) {
			try {
				const response = await apolloClient.mutate({
					mutation: UPDATE_BONUSES,
					variables: {
						updateBonusInput: payload,
					},
				});
				if (response?.data?.updateBonuses?.success) {
					await context.dispatch("fetchBonuses", 1);
					return { message: "Bonus Updated Successfully" };
				}
			} catch (error) {
				console.log(error);
			}
		},

		//UPDATE ALLOWANCE ON SERVER
		editAllowance(context, payload) {
			return new Promise((resolve, reject) => {
				/*
				api.post(endpoint.editAllowanceEndpoint, payload)
         */
				apolloClient
					.mutate({
						mutation: UPDATE_ALLOWANCE,
						variables: {
							allowanceInput: payload,
						},
					})
					.then(() => {
						context.dispatch("fetchAllowance", 1).then(() => {
							resolve({ message: "Allowance Updated Successfully" });
						});
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		//DELETE BONUS FROM SERVER
		deleteBonus(_context, payload) {
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: DELETE_BONUS,
						variables: {
							deleteByIdInput: { id: payload },
						},
					})
					.then((response) => {
						resolve(response.data.deleteBonus);
					})
					.catch((error) => {
						console.error(error);
						reject(error);
					});
			});
		},

		//DELETE ALLOWANCE FROM SERVER
		deleteAllowance(_context, payload) {
			return new Promise((resolve, reject) => {
				// api.delete(`${endpoint.deleteAllowanceEndpoints}/${payload}`)
				apolloClient
					.mutate({
						mutation: DELETE_ALLOWANCE,
						variables: {
							allowanceInput: [{ id: payload }],
						},
					})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		//SEARCH BONUS ON SERVER
		async searchBonuses({ commit }, query) {
			try {
				let response = await apolloClient.query({
					query: SEARCH_BONUSES,
					variables: {
						searchInput: query.trim(),
						page: 0,
						size: 20,
					},
				});
				if (response.status == 200 || response.status !== 204) {
					let queryResult = response.data.listBonusByDisplayName.data;
					commit("SEARCH_BONUS", queryResult);
					commit(
						"SET_CURRENT_PAGE",
						response.data.listBonusByDisplayName.pageable.number
					);
					commit(
						"SET_TOTAL_PAGE",
						response.data.listBonusByDisplayName.pageable.totalPages
					);
				}
			} catch (error) {
				console.log(error);
			}
		},

		//SEARCH ALLOWANCE ON SERVER
		async searchAllowances({ commit }, query) {
			try {
				let response = await apolloClient.query({
					query: SEARCH_FOR_ALLOWANCE,
					fetchPolicy: "network-only",
					variables: {
						searchInput: query.trim(),
						size: 20,
						page: 0,
					},
				});

				let queryResult = response.data.listAllowanceByDisplayName;
				commit("SEARCH_ALLOWANCE", queryResult);
				commit("SET_CURRENT_PAGE", queryResult.pageable.pageNumber);
				commit("SET_TOTAL_PAGE", queryResult.pageable.totalPages);
			} catch (error) {
				console.log(error);
			}
		},
		async fetchAllTags({ commit }) {
			// api.get("/tags")
			apolloClient
				.query({
					query: LIST_TAGS,
					variables: {
						size: 100,
						page: 0,
					},
				})
				.then((response) => {
					commit("SET_TAGS", response.data.listTags.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
