import { apolloClient } from "../../../apollo/apolloVueClient";
import {
	UPDATE_PENSION,
	DELETE_PENSION,
} from "../../../graphql/mutations/pensions_mutations";
import {
	CREATE_TAX,
	DELETE_TAXES,
	UPDATE_TAXES,
} from "../../../graphql/mutations/paye_mutations";
import { CREATE_PENSION } from "../../../graphql/mutations/createPension";

// initial state
export default {
	namespaced: true,
	state: {
		taxes: [],
		ssnit: [],
		currentTab: null,
	},

	//getters
	getters: {
		hasTax: (state) => {
			return state.taxes && state.taxes.length > 0;
		},
		hasSsnit: (state) => {
			return state.ssnit && state.ssnit.length > 0;
		},
		currentTab: (state) => {
			return state.currentTab || "tax";
		},
	},

	// mutations
	mutations: {
		SET_CURRENT_TAB(state, payload) {
			state.currentTab = payload;
		},
		ADD_TAX(state, payload) {
			let taxes = state.taxes.concat(payload);
			state.taxes = taxes;
		},
		ADD_SSNIT(state, payload) {
			let ssnit = state.ssnit.concat(payload);
			state.ssnit = ssnit;
		},
		EDIT_TAX(state, payload) {
			state.taxes = state.taxes.map((definition) => {
				if (definition.id == payload.taxId) {
					return payload;
        }
        return definition
			});
		},
		EDIT_SSNIT(state, payload) {
			state.ssnit = state.ssnit.map((definition) => {
				if (definition.id == payload.id) {
					return payload;
        }
        return definition
			});
		},
		DELETE_TAX(state, empID) {
			let taxes = state.taxes.filter((emp) => emp.id != empID);
			state.taxes = taxes;
		},
	},

	// actions
	actions: {
		//CREATE TAXES  ON SERVER
		async createTax({ commit }, payload) {
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: CREATE_TAX,
						variables: { input: payload },
					})
					.then((response) => {
						commit("ADD_TAX", response.data.createPayeConfigs.data);
						resolve(response.data.createPayeConfigs);
					})
					.catch((error) => {
						console.error(error);
						reject(error);
					});
			});
		},

		async createSsnit({ commit }, payload) {
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: CREATE_PENSION,
						variables: {
							pensionInput: payload,
						},
					})
					.then((response) => {
						commit("ADD_SSNIT", reject.data.createPensions.data);
						resolve(response.data.createPensions);
					})
					.catch((error) => {
						reject(error);
						console.error(error);
					});
			});
		},
		//UPDATE TAXES ON SERVER
		async editTax(context, payload) {
			return new Promise((resolve, reject) => {
				const input = {
					id: String(payload.id),
					label: payload.label,
					percentage: payload.percentage,
					amount: payload.amount,
				};
				// api
				//   .patch(endpoint.editTaxEndpoints, payload)
				apolloClient
					.mutate({
						mutation: UPDATE_TAXES,
						fetchPolicy: "network-only",
						variables: {
							input: input,
						},
					})
					.then((response) => {
						resolve(response.data.updatePayeConfig);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},
		//UPDATE TAXES ON SERVER
		async editSsnit(context, payload) {
			const input = {
				id: payload.id,
				label: payload.label,
				percentage: parseFloat(payload.percentage),
			};
			return new Promise((resolve, reject) => {
				apolloClient
					.mutate({
						mutation: UPDATE_PENSION,
						variables: {
							input: input,
						},
					})
					.then((res) => {
						resolve(res.data.updatePension);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		//DELETE TAX ON SERVER
		async deleteTax({ commit }, payload) {
			try {
				let response = await apolloClient.mutate({
					mutation: DELETE_TAXES,
					variables: {
						id: payload.id,
					},
				});

				const { data } = response;
				if (
					data?.deletePayeConfig?.success
				) {
					commit("DELETE_TAX", payload.data?.deletePayeConfig);
				} else {
					throw new Error("Failed to delete tax.");
				}
			} catch (error) {
				console.error("Error deleting tax:", error);
			}
		},
		async deleteSnit({ commit }, payload) {
			//DELETE SSNIT ON SERVER
			try {
				let response = await apolloClient.mutate({
					mutation: DELETE_PENSION,
					variables: {
						pensionInput: payload.id,
					},
				});
				if (response.status == 200 || response.status !== 204) {
					commit("DELETE_SSNIT", payload.id);
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
