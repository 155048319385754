<template>
	<component :is="resolveLayout">
		<v-snackbar :timeout="5000" :value="snackBar" v-model="snackBar" top :color="isError ? 'error' : 'success'" center
			outlined elevation="24" text>
			<div class="d-flex justify-content-between">
				<span class="text-left">{{ snackBarText }}</span>
				<span class="text-right">
					<v-icon color="error" @click="snackBar = false">mdi-close</v-icon>
				</span>
			</div>
		</v-snackbar>
		<v-fade-transition mode="out-in">
			<router-view />
		</v-fade-transition>
	</component>
</template>

<script>
import LayoutBlank from "./components/layouts/Blank.vue";
import LayoutContent from "./components/layouts/Layout.vue";

export default {
	name: "App",
	components: {
		LayoutBlank,
		LayoutContent,
	},
	data: () => ({
		snackBar: false,
		snackBarText: "",
		isError: false,
	}),
	computed: {
		resolveLayout() {
			if (this.$route.name === null) return null;

			if (this.$route.meta.layout === "blank") return "layout-blank";

			return "layout-content";
		},
		payrollDate() {
			return this.$store.state.payroll.payrollDate;
		},
	},

	mounted() {
		Fire.$on("payroll-error", (message) => {
			this.snackBar = true;
			this.isError = true;
			this.snackBarText = message;
		});

		Fire.$on("payroll-success", async (message) => {
			this.snackBar = true;
			this.snackBarText = message;
			setTimeout(() => {
				window.location.reload();
			}, 5500);
		});
	},
};
</script>

<style>
.v-application {
	background-color: var(--v-background-base) !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
	color: #404040;
	font-size: 0.9rem;
	background-color: #f5f6fa;
	padding: 1rem;
	font-weight: 900 !important;
}

.theme--light.v-tabs-items {
	background-color: transparent !important;
}

.slide-leave-active {
	transition: opacity 0.3s ease;
	opacity: 0;
	animation: slide-out 0.3s ease-out forwards;
}

.slide-leave {
	opacity: 1;
	transform: translateX(0);
}

.slide-enter-active {
	animation: slide-in 0.3s ease-out forwards;
}

.O400 {
  color: #dd5928 !important;
}

.H700 {
  font-weight: 700;
  font-size: 1.44rem;
  line-height: 28px;
}

.N900 {
  color: #101840;
}

.SPC-MR-100 {
  margin-right: 8px;
}

.SPC-MR-200 {
	margin-right: 16px;
}

.P100 {
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 16px;
}

.P200 {
	font-weight: 400;
	font-size: 1rem;
	line-height: 36px;
}

.N600 {
  color: #8f95b2;
}


.N800 {
	color: #474d66;
}

.alertBox {
	display: flex;
	justify-content: space-between;
	padding: 0.55rem;
	font-size: 0.875rem;
	border-width: 1px;
	border-style: solid;
	border-color: #E6E8F0;
	color: #4A5568;
	background-color: #FAFBFF;
	border-radius: 0.5rem;
}

.SPC-MB-300 {
	margin-bottom: 24px;
}


.access_container {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	overflow-x: hidden;
	margin-bottom: 0.5rem;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

@keyframes slide-out {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-30px);
	}
}

@keyframes slide-in {
	0% {
		transform: translateY(-30px);
	}

	100% {
		transform: translateY(0);
	}
}

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px white;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c4c3c3;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #888888;
}

.table-bordered {
	border: 1px solid #dee2e6;
}

.v-pagination__item {
	font-size: 0.7rem !important;
	height: 25px !important;
	margin: 0.1rem !important;
	min-width: 24px !important;
}

.v-pagination__navigation {
	height: 25px !important;
	width: 25px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
	font-size: 0.9rem !important;
	font-weight: 300 !important;
	height: 70px !important;
	color: #000 !important;
}
</style>
