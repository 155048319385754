import * as endpoint from "../../../endpoints";
import apiInstance from "../../../api";

const api = apiInstance();

export default {
state: {
  password: [],
  isError: null,
},

getters: {
  isNewPassword: (state) => {
    return state.password;
  },
},

mutations: {
  SET_NEW_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_CHANGE_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_FORGOTTEN_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_ERROR(state, payload) {
    state.isError = payload;
  },
},

// A REQUEST TO SET NEW PASSWORD
actions: {
  async setNewPassword(context, payload) {
    try {
      const response = await api.patch(endpoint.setNewPassword, payload);
      if (response.status == 200) {
        context.commit("SET_NEW_PASSWORD", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },

  //A REQUEST FOR CHANGE PASSWORD
  async changePassword(context, payload) {
    try {
      const response = await api.patch(endpoint.changePassword, payload);
      if (response.status == 200) {
        context.commit("SET_CHANGE_PASSWORD", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },

  //POST EMAIL FOR FORGOTTEN PASSWORD
  async forgottenPassword(context, payload) {
    try {
      const response = await api.post(endpoint.forgottenPassword, payload);
      if (response.status == 200) {
        context.commit("SET_FORGOTTEN_PASSWORD", response.data);
      }
    } catch (error) {
      if (error) {
        context.commit("SET_ERROR", error.message);
      }
    }
  },
}
}
