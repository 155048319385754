import * as endpoint from "../../../endpoints";
import apiInstance from "../../../api";

const api = apiInstance();

export default {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    EDIT_USERS(state, payload) {
      state.users = payload;
    },
    ADD_USERS(state, payload) {
      state.users = payload;
    },
    DELETE_USER(state, payload) {
      state.users = payload;
    },
  },

  //GET USERS FROM SERVER
  actions: {
    fetchUsers({ commit }) {
      api.get(endpoint.getAllUsers.concat("/")).then((response) => {
        commit("SET_USERS", response.data);
      });
    },
    //ADD USER TO SERVER
    async addUser({ commit }, payload) {
      try {
        let response = await api.post(endpoint.registerUser, payload);
        commit("ADD_USERS", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    //UPDATE USERS ON SERVER
    async editUser({ commit }, payload) {
      try {
        let response = await api.post(endpoint.updateUser, payload);
        commit("EDIT_USERS", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    //DELETE USER ON SERVER
    async deleteUser({ commit }, payload) {
      const uid = [];
      uid.push(payload.uid);
      try {
        let response = await api.delete(endpoint.deleteUser, { data: uid });
        commit("DELETE_USER", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    //UPDATE USER STATUS ON SERVER
    async updateStatus({ commit }, payload) {
      try {
        let response = await api.patch(endpoint.userStatus, payload);
        commit("UPDATE_STATUS", response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    allUsers: (state) => state.users,
  }
}
