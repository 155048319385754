<template>
  <div>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        
        circle
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: ["action", "module"],
  data: () => ({
    isloading: false,
  }),
  inject: ["department"],
  computed: {
    totalPages() {
      return this.$store.getters[`${this.module}/totalPages`];
    },
    page: {
      get() {
        return this.$store.getters[`${this.module}/currentPage`];
      },
      set(val) {
        return this.$store.commit(`${this.module}/SET_CURRENT_PAGE`, val - 1);
      },
    },
  },
  methods: {
    async paginatePage() {
      this.isloading = true;
      this.scrollToTop();
      await this.$store.dispatch(`${this.module}/${this.action}`, {
        data1: this.department,
        data2: this.page,
      });
      
      this.isloading = false;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
