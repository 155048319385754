import axios from "axios";
import Cookies from "js-cookie";
import config from "../../public/config";
export default () => {
	const authToken = Cookies.get("app-token");
	return axios.create({
		baseURL: config.api,
		withCredentials: false,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: authToken ? `Bearer ${authToken}` : null,
		},
	});
};
