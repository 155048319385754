<template>
    <v-card class="p-5">
        <v-card-text class="d-flex justify-content-center text-center">
            <h5>{{message}}</h5>
        </v-card-text>
        <v-card-actions class=" justify-center text-h5 text-center ">
            <v-btn color="secondary" class="buttonStyle" small @click="negativeResponse">
                No
            </v-btn>
            <v-btn color="primary" class="buttonStyle" small @click.prevent="positiveResponse">
                Yes
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "ConfirmComponent",
        props: ['message'],
        methods: {
            negativeResponse(){
                this.$emit('cancel')
            },
            positiveResponse(){
                this.$emit('success')
            }
        }
    }
</script>

<style scoped>
    .buttonStyle{
        width: 2rem;
        color: #fff;
    }

</style>