import { apolloClient } from "../../../apollo/apolloVueClient";
import {GET_TEAM_PERFORMANCE, GET_KPI} from "../../../graphql/queries/teamPerformance.js";


export default {
    namespaced: true,
    state: {
        teamPerformance: [],
        currentPage: null,
        totalPages: null,
        kpi: [],
        tpDate: "",
    },
    getters: {
        teamPerformance: (state) => state.teamPerformance,
        currentPage: (state) => state.currentPage || 1,
        totalPages: (state) => state.totalPages,
        kpi: (state) => state.kpi,
        currentTpDate: (state) => state.tpDate,
    },
    mutations: {
        SET_TEAM_PERFORMANCE(state, newTeamPerformance) {
            state.teamPerformance = newTeamPerformance;
        },
        SET_PAGE(state, newPage) {
            state.currentPage = newPage;
        },
        SET_TOTAL_PAGES(state, newTotalPages) {
            state.totalPages = newTotalPages;
        },
        SET_KPI(state, newKpi) {
            state.kpi = newKpi;
        },
        SET_TP_DATE(state, newTpDate) {
            state.tpDate = newTpDate;
        },
    },
    actions: {
        async getTeamPerformance({ commit }, payload) {
            const { data } = await apolloClient.query({
                query: GET_TEAM_PERFORMANCE,
                variables: {
                    page: payload.page-1,
                    size: payload.size,
                    yearMonth: payload.yearMonth,
                    searchName: payload.searchName
                },
            });
            commit("SET_TEAM_PERFORMANCE", data?.listPayrollEmployeesWithTeamPerformance?.data);
            commit("SET_PAGE", data?.listPayrollEmployeesWithTeamPerformance?.pageable.number);
            commit("SET_TOTAL_PAGES", data?.listPayrollEmployeesWithTeamPerformance?.pageable.totalPages);
        },
        async getKpi({ commit }, payload) {
            const { data } = await apolloClient.query({
                query: GET_KPI,
                variables: {
                    page: payload.page-1,
                    size: payload.size,
                    yearMonth: payload.yearMonth,
                    searchName: payload.searchName,
                    startWith: payload.startWith
                },
            });
            commit("SET_KPI", data?.listPayrollEmployeesWithKpiBonus?.data);
            commit("SET_PAGE", data?.listPayrollEmployeesWithKpiBonus?.pageable.number);
            commit("SET_TOTAL_PAGES", data?.listPayrollEmployeesWithKpiBonus?.pageable.totalPages);
        },
    },
}