import {
	LIST_ACTIVE_EMPLOYEES,
	LIST_EMPLOYEES_BY_NAME,
	LIST_EMPLOYEES_BY_ID_NAME,
} from "../../../graphql/queries/allQueries";
import { apolloClient } from "../../../apollo/apolloVueClient";

export default {
	namespaced: true,
	state: {
		employees: {},
		employeeIdsNames: [],
		currentPage: null,
		totalPages: null,
		trial: 0,
		pageLoading: false,
	},
	getters: {
		allEmployees: (state) => {
			return state.employees || [];
		},
		employeeIdsNames: (state) => {
			return state.employeeIdsNames;
		},
		hasEmployees: (state) => {
			return (
				state?.employees?.data?.length > 0
			);
		},
		currentPage: (state) => {
			return state.currentPage;
		},
		totalPages: (state) => {
			return state.totalPages;
		},
		pageLoading: (state) => {
			return state.pageLoading;
		},
		getNumber: (state) => {
			return state.employees.number;
		},
	},
	mutations: {
		SET_EMPLOYEES(state, payload) {
			state.employees = payload;
		},
		SET_EMPLOYEES_IDS_NAMES(state, payload) {
			state.employeeIdsNames = payload.filter((emp) => emp.status);
		},
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = payload;
		},

		SET_CURRENT_PAGE(state, payload) {
			state.currentPage = payload;
		},
		SET_PAGE_LOADING(state, payload) {
			state.pageLoading = payload;
		},

		EDIT_EMPLOYEES(state, payload) {
			state.employees = state.employees.map((emp) => {
				if (emp.id == payload.employeeID) {
					return payload;
				}
				return emp;
				// window.location.reload(true)
			});
		},

		SEARCH_EMPLOYEES(state, payload) {
			state.employees = payload;
		},

		DELETE_EMPLOYEE(state, empID) {
			let employees = state.employees.filter((emp) => emp.id != empID);
			state.employees = employees;
		},
	},

	actions: {
		//GET ALL EMPLOYEES FROM SERVER
		async fetchEmployees(context, pageNumber) {
			context.commit("SET_PAGE_LOADING", true);
			apolloClient
				.query({
					query: LIST_ACTIVE_EMPLOYEES,
					variables: { page: pageNumber - 1, size: 25 },
				})
				.then((response) => {
					const listData = response.data.listActiveEmployees;
					context.commit("SET_EMPLOYEES", listData.data);
					context.commit("SET_PAGE_LOADING", false);
					context.commit("SET_CURRENT_PAGE", listData.pageable.number + 1);
					context.commit("SET_TOTAL_PAGES", listData.pageable.totalPages);
				})
				.catch(() => {
					context.commit("SET_TRIAL", context.state.employees.trial + 1);
					if (context.state.employees.trial < 3) {
						context.dispatch("fetchEmployees", pageNumber);
					} else {
						context.commit("SET_EMPLOYEES", {});
						context.commit("SET_PAGE_LOADING", false);
					}
				});
		},

		async fetchEmployeesIdsNames(context) {
			apolloClient
				.query({
					query: LIST_EMPLOYEES_BY_ID_NAME,
				})
				.then((response) => {
					context.commit(
						"SET_EMPLOYEES_IDS_NAMES",
						response.data.listEmployeesWithIdAndNamesOnly
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async searchEmployees({ commit }, query) {
			let editedQuery =  query.charAt(0).toUpperCase() + query.slice(1);
			commit("SET_PAGE_LOADING", true);
			apolloClient
				.query({
					query: LIST_EMPLOYEES_BY_NAME,
					variables: {
						searchName: editedQuery.trim(),
					},
				})
				.then((response) => {
					const listData = response.data.listEmployeesByName;
					commit("SET_EMPLOYEES", listData.data);
					commit("SET_PAGE_LOADING", false);
					commit("SET_CURRENT_PAGE", listData.pageable.number + 1);
					commit("SET_TOTAL_PAGES", listData.pageable.totalPages);
				})
				.catch((error) => {
					console.log(error);
					commit("SET_PAGE_LOADING", false);
				});
		},
	},
};
