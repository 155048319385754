<template>
  <v-overlay :value="isloading">
    <v-progress-circular
      indeterminate
      size="100"
      color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    isloading: true,
  }),
};
</script>
